var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-snackbar',{attrs:{"color":"#68D389","right":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"align-self-center",attrs:{"text-left":""}},[_c('span',{staticStyle:{"color":"#ffffff"}},[_vm._v(" "+_vm._s(_vm.msg)+" ")])]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',{staticStyle:{"color":"#ffffff"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":""}},[_c('v-card',{staticClass:"mr-4",attrs:{"tile":"","elevation":"0","color":"#ED7243","height":"80px","width":"90px"},on:{"click":function($event){return _vm.$refs.files.click()}}},[(!_vm.singleImage && !_vm.resultImage)?_c('v-layout',{attrs:{"wrap":"","fill-height":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":"","text-center":""}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus-circle-outline")])],1)],1):_vm._e(),(_vm.singleImage && !_vm.resultImage)?_c('v-img',{attrs:{"src":_vm.mediaUURL + _vm.singleImage,"height":"80px","width":"90px"}}):_c('v-img',{attrs:{"src":_vm.resultImage,"height":"80px","width":"90px"}}),_c('v-dialog',{attrs:{"persistent":"","width":_vm.$vuetify.breakpoint.name == 'xs' || _vm.$vuetify.breakpoint.name == 'sm'
        ? '100vw'
        : _vm.$vuetify.breakpoint.name == 'md'
        ? '80vw'
        : _vm.$vuetify.breakpoint.name == 'lg'
        ? '50vw'
        : '40vw'},model:{value:(_vm.isCropper),callback:function ($$v) {_vm.isCropper=$$v},expression:"isCropper"}},[_c('v-card',{staticClass:"pb-3"},[_c('v-card-title',{staticClass:"headline"},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('span',[_vm._v(" Crop Image")])]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.isCropper = false}}},[_c('v-icon',{staticStyle:{"color":"#red"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('Cropper',{ref:"cropper",staticClass:"example-cropper",attrs:{"stencil-props":{
                aspectRatio: _vm.width / _vm.height,
              },"src":_vm.image}}),_c('v-card-actions',[_c('v-layout',{attrs:{"wrap":"","justify-center":"","pt-3":""}},[_c('v-flex',{attrs:{"xs4":"","md3":""}},[_c('v-btn',{staticClass:"py-2 px-5",attrs:{"small":"","ripple":false,"color":"#68D389"},on:{"click":_vm.cropImage}},[_c('span',{staticStyle:{"color":"#ffffff","font-family":"poppinsregular"}},[_vm._v("Crop")])])],1),_c('v-flex',{attrs:{"xs8":"","md3":""}},[_c('v-btn',{staticClass:"py-2 px-3",attrs:{"small":"","ripple":false,"color":"#68D389"},on:{"click":function($event){return _vm.$refs.files.click()}}},[_c('span',{staticStyle:{"color":"#ffffff","font-family":"poppinsregular"}},[_vm._v("Change Image")])])],1)],1)],1)],1)],1)],1),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"files",attrs:{"accept":"image/*","id":"file1","type":"file"},on:{"change":_vm.browseImage}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }